<template>
    <div>

    </div>
</template>

<script>
import Actions from '../libraries/apiActions.js'
export default{
    name: 'siteMap',  
    data(){
        return{
          action: new Actions(),
           jobs:[],
           total: 0,
           filter: {
               limit: 0
           }
        }
    },
    beforeMount(){
        this.loadInitialProjects();
    },
    methods: {  
        createSitemap: function() {
            var doc = document.implementation.createDocument('', '', null);
            //create the outer tag
            var urlset = doc.createElement("urlset");
            urlset.setAttribute("xmlns", "http://www.sitemaps.org/schemas/sitemap/0.9");
            var url = ''; var changefreq = ''; var loc = ''; var priority = ''; var lastmod = '';
                        
            //first create static sites (examples)
            var staticSites = ['', 'jobs','terms', 'privacy', 'jobseeker/register'];
            for (var i=0; i < staticSites.length; i++) {
                url = doc.createElement("url");
                loc = doc.createElement('loc');
                loc.innerHTML = process.env.VUE_APP_URL + staticSites[i];
                changefreq = doc.createElement("changefreq");
                priority = doc.createElement("priority");
                lastmod = doc.createElement("lastmod");
                changefreq.innerHTML = 'weekly';
                priority.innerHTML = 1.0;
                lastmod.innerHTML = (new Date()).toISOString().split('T')[0];
                url.appendChild(loc);
                url.appendChild(changefreq);
                url.appendChild(priority);
                url.appendChild(lastmod);
                urlset.appendChild(url);
            }      

            //append dynamic articles/projects urls 
            this.jobs.forEach(job => {
                 url = doc.createElement("url");
                loc = doc.createElement('loc');
                let param = (job.seo_description)? job.seo_description:'';
                param += job.job_id?(job.seo_description)?'-'+job.job_id:job.job_id:'';
                param = param.replace(/[^A-Z0-9]+/ig, "-");
                loc.innerHTML = process.env.VUE_APP_URL + 'jobs/'+param;
                changefreq = doc.createElement("changefreq");
                changefreq.innerHTML = 'weekly';
                priority = doc.createElement("priority");
                priority.innerHTML = 1.0;
                lastmod = doc.createElement("lastmod");
                lastmod.innerHTML = (new Date()).toISOString().split('T')[0];
                url.appendChild(loc);
                url.appendChild(changefreq);
                url.appendChild(priority);
                url.appendChild(lastmod);
                urlset.appendChild(url);
            });
            
            
            doc.appendChild(urlset);
            //serialize the xml file to txt
            var oSerializer = new XMLSerializer();
            var xmltext = oSerializer.serializeToString(doc);
            xmltext = '<?xml version="1.0" encoding="UTF-8"?>' + xmltext;
            console.log(xmltext);
            //download the txt
            var pom = document.createElement('a');
            var filename = "sitemap.xml";
            var bb = new Blob([xmltext], {type: 'text/plain'});
            pom.setAttribute('href', window.URL.createObjectURL(bb));
            pom.setAttribute('download', filename);
            pom.dataset.downloadurl = ['text/plain', pom.download, pom.href].join(':');
            pom.draggable = true; 
            pom.classList.add('dragout');
            pom.click();
         
            
        },
        
           //======== METHODS FOR USER CONTENT ========
        loadInitialProjects() {
            this.action.getJobList().then(res => {
                this.jobs = res.jobs
                this.count = res.meta.total;
                if(this.count > 25) {
                    this.filter.limit = this.count;
                    this.action.getJobList(this.filter).then(res => {
                        this.jobs = res.jobs;
                        this.createSitemap(); 
                        this.$router.push("/");
                        location.reload();
                    });
                } else {
                        this.createSitemap(); 
                        this.$router.push("/");
                        location.reload();
                }
                   
            });       
        }
    }
        
}
</script>